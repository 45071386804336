<template>
  <v-app>

    <div style="bottom:140px;right:20px;position:fixed;z-index:10000">
      <v-btn href="https://instagram.com/inmoglobalve" target="_blank"
      text icon x-large style="
      box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -webkit-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -moz-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      font-size:20px;height:50px;width:50px;background:#00174A;color:white;border-radius:50%;">
        <i class="fab fa-facebook-f" style="padding:10px"></i>
      </v-btn>
    </div>
    <div style="bottom:80px;right:20px;position:fixed;z-index:10000">
      <v-btn href="https://instagram.com/inmoglobalve" target="_blank"
      text icon x-large style="
      box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -webkit-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -moz-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      font-size:20px;height:50px;width:50px;background:#00174A;color:white;border-radius:50%;">
        <i class="fab fa-instagram" style="padding:10px"></i>
      </v-btn>
    </div>
    <div style="bottom:20px;right:20px;position:fixed;z-index:10000">
      <v-btn href="https://api.whatsapp.com/send?phone=584144123718&text=%20" target="_blank"
      text icon x-large style="
      box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -webkit-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      -moz-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.78);
      font-size:20px;height:50px;width:50px;background:#00174A;color:white;border-radius:50%;">
        <i class="fab fa-whatsapp" style="padding:10px"></i>
      </v-btn>
    </div>


    <div class="sec hidden-sm-and-down">
      <barMenu></barMenu>
          <video src="https://inmoglobalve.com.ve/videos/familia.mp4"
          style="z-index:-1000;width:100%;margin-top:-60px;"
           muted loop autoplay></video>
    </div>

    <div class="sec2 hidden-md-and-up">
      <barMenu></barMenu>
          <video src="https://inmoglobalve.com.ve/videos/familia.mp4"
          style="z-index:-1000;width:100%;margin-top:-60px;"
           muted loop autoplay></video>
    </div>
    
    <v-main class="backgroundApp">
      <div style="margin:0 auto;display:block;width:90%">
        <router-view></router-view>
      </div>
      
    </v-main>



    <div style="margin-top:100px;background:#f7f7f7;width:100%;margin-bottom:20px;">
      <div style="width:90%;margin:20px auto;display:block;">
        <v-layout row justify-center>
          <v-flex xs12 md3>
            <div style="width:95%;margin:0 auto;display:block">
              <img src="https://inmoglobalve.com.ve/img/logoAz.png" width="100"
              style="margin:0 auto;display:block;"
              >
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div style="width:95%;margin:0 auto;display:block">
              <p class="black--text title">
                Inicio
              </p>
              <p style="#333">
                Información <br><br>
                Búsqueda de Inmueble
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div style="width:95%;margin:0 auto;display:block">
              <p class="black--text title">
                Quienes Somos
              </p>
              <p style="#333">
                Nuestra Misión <br><br>
                Nuestra Visión<br><br>
                Valores Global<br><br>
              </p>
            </div>
          </v-flex>
          <v-flex xs12 md3>
            <div style="width:95%;margin:0 auto;display:block">
              <p class="black--text title">
                Team Global
              </p>
              <p style="#333">
                Junta Directiva<br><br>
                Nuestros Asesores<br><br>
              </p>
            </div>
          </v-flex>
        </v-layout>
      </div>
      <v-divider class="my-5"></v-divider>
      <v-card flat style="background:transparent;width:90%;margin:0 auto;display:block;">
        <v-card-actions>
          © 2022 INMOGLOBALVE
          <v-spacer></v-spacer>
          <a href="https://curubasoft.com" target="_blank" style="text-decoration:none;color:#333">
          Creado Por <br>
          <img src="https://curubasoft.com/img/logo2.png" width="100" alt="">
          </a>
        </v-card-actions>
      </v-card>
    </div>





  </v-app>
</template>

<style>

.sec {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}
.sec2 {
  position: relative;
  width: 100%;
  overflow: hidden;
}



* {
  font-family: 'Nunito Sans', sans-serif;
}
*::selection{
  background: #0083ee5e;
  color: white;
}
.backgroundApp {
background: rgba(0, 23, 74, 0.08);

}
</style>

<script>
import barMenu from './components/barMenu'
import store from './store/index'
export default {
  name: 'App',
  data: () => ({
    //
  }),
  components:{
    barMenu
  },
  methods:{
    loadVerDolar(){
      //https://s3.amazonaws.com/dolartoday/data.json
      fetch('https://s3.amazonaws.com/dolartoday/data.json')
      .then(response => response.json())
      .then(data =>{
        store.commit('setDolar', Math.trunc(data.USD.transferencia))
      })
        }
  },
  created(){
    this.loadVerDolar()
  }
};
</script>
