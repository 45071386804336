<template>
    <div style="position:relative;z-index:1000">
        <v-app-bar height="40" color="#00174A">
      <div style="width:90%;margin:0 auto;display:block;">
        <v-btn small icon text color="white">
          <i class="fab fa-facebook-f"></i>
        </v-btn>
        <v-btn small icon text color="white" href="https://instagram.com/inmoglobalve" target="_blank">
          <i class="fab fa-instagram"></i>
        </v-btn>
        <v-btn small icon text color="white" href="https://instagram.com/inmoglobalve" target="_blank">
          <i class="fab fa-youtube"></i>
        </v-btn>
        <v-btn small icon text color="white" href="https://tiktok.com/@inmoglobalve" target="_blank">
          <i class="fab fa-tiktok"></i>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text hidden-md-and-up"></v-app-bar-nav-icon>
    </v-app-bar>


        <v-app-bar flat style="background:transparent;" height="60">
            <div style="margin:0 auto;display:block;">
                <router-link style="color:white;"
                class="hidden-sm-and-down mx-1 my-2 butonses"
            v-for="(item, i) in items" :key="i" :to="item.link">
                {{item.name}}
            </router-link>
            </div>
        </v-app-bar>


        <v-navigation-drawer app v-model="drawer" width="100%">

            <p class="text-center pt-5" style="font-size:12px;font-style:italic;color:#ccc">
              Deslizar hacia la izquierda para cerrar.
            </p>

            <v-img src="https://inmoglobalve.com.ve/img/logoAz.png"
            style="margin:20px auto;display:block;" width="100"
            ></v-img>
            <router-link class="mt-2" style="border-radius:10px;padding:5px 0px;text-decoration:none;color:#fff;background:#00174A;
            width:90%;margin:0 auto;display:block;text-align:center;
            "
            active-class="bodss2"
            v-for="(item, i) in items" :key="i" :to="item.link">
                {{item.name}}
            </router-link>
        </v-navigation-drawer>
    </div>
</template>

<style>
.butonses {
text-decoration:none;background:transparent;
            border-radius:10px;padding:10px 10px;
            transition: all 2s;
}
.butonses:hover{
  background: rgba(0, 53, 102, 0.301);
background: -moz-linear-gradient(top, rgba(0,53,102,0.2) 0%, rgba(0,91,189,0.2) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,53,102,0.2)), color-stop(100%, rgba(0,91,189,0.2)));
background: -webkit-linear-gradient(top, rgba(0,53,102,0.2) 0%, rgba(0,91,189,0.2) 100%);
background: -o-linear-gradient(top, rgba(0,53,102,0.2) 0%, rgba(0,91,189,0.2) 100%);
background: -ms-linear-gradient(top, rgba(0,53,102,0.2) 0%, rgba(0,91,189,0.2) 100%);
background: linear-gradient(to bottom, rgba(0,53,102,0.2) 0%, rgba(0,91,189,0.2) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003566', endColorstr='#005bbd', GradientType=0 );
}
.dfd{
  color: #7400006e;
}

.bodss {
    border-bottom: solid 2px #00174a;
}
.backgroundApp {
  background: rgba(0,131,238,0.05);
background: -moz-linear-gradient(top, rgba(0,131,238,0.05) 0%, rgba(39,115,230,0.02) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,131,238,0.05)), color-stop(100%, rgba(39,115,230,0.02)));
background: -webkit-linear-gradient(top, rgba(0,131,238,0.05) 0%, rgba(39,115,230,0.02) 100%);
background: -o-linear-gradient(top, rgba(0,131,238,0.05) 0%, rgba(39,115,230,0.02) 100%);
background: -ms-linear-gradient(top, rgba(0,131,238,0.05) 0%, rgba(39,115,230,0.02) 100%);
background: linear-gradient(to bottom, rgba(0,131,238,0.05) 0%, rgba(39,115,230,0.02) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0083ee', endColorstr='#2773e6', GradientType=0 );
}
</style>
<script>
export default {
    data(){
        return {
            drawer:false,
            items:[
                {name: 'Inicio', link: '/'},
                {name: 'Quienes Somos', link: '/Nosotros'},
                {name: 'Nuestras Oficinas', link: '/Oficinas'},
                {name: 'Solicita Un Inmueble', link: '/Inmuebles'},
                {name: 'Team Global', link: '/TeamGlobal'},
                {name: 'Contáctanos', link: '/Contactos'}
            ]
        }
    }
}
</script>