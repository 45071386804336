import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Inmuebles/:id',
    name: 'InmueblesUltimos',
    component: () => import('../components/InmueblesUltimos.vue')
  },
  {
    path: '/Busqueda',
    name: 'ResultSearch',
    component: () => import('../components/BuscadorResult.vue')
  },
  {
    path: '/BusquedaRapida',
    name: 'BusquedaRapida',
    component: () => import('../components/BuscadorResultFast.vue')
  },
  {
    path: '/Nosotros',
    component: () => import('../components/Nosotros.vue')
  },
  {
    path: '/Oficinas',
    component: () => import('../components/Oficinas.vue')
  },
  {
    path: '/Inmuebles',
    component: () => import('../components/Inmuebles.vue')
  },
  {
    path: '/TeamGlobal',
    component: () => import('../components/TeamGlobal.vue')
  },
  {
    path: '/Contactos',
    component: () => import('../components/Contacto.vue')
  },
  {
    path: '/UltimosInmuebles/:id',
    name: 'visualizar',
    component: () => import('../components/InmueblesNuevos.vue')
  },
  {
    path: '/Asesor',
    name: 'misInmueblesAsesor',
    component: () => import('../components/InmueblesPorAsesor/index.vue')
  },
  {
    path: '/Inmueble',
    name: 'verProducto',
    component: () => import('../components/VerInmueble.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
