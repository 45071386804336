import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    busqueda: [],dolarDiario:'',
  },
  mutations: {
    resultadoBusqueda(state, e){
      state.busqueda = e
    },
    setDolar(state, e){
      state.dolarDiario = e
    }
  },
  actions: {
  },
  modules: {
  }
})
